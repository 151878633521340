const userAgent = window.navigator?.userAgent?.toLowerCase?.() || ''
// @ts-ignore Checking documentMode to detect IE
const isIEbyDocumentMode = !!window.document?.documentMode
// eslint-disable-next-line
const isIEByUA = userAgent.match(/msie\s([\d.]+)/) || userAgent.match(/trident\/[\d](?=[^\?]+).*rv:([0-9.].)/)

if (isIEbyDocumentMode || isIEByUA) {
	window.__IE_deprecation__ = true // Used to stop the JS flow in clientRunner

	hideBody() // Hide the HTML generated by SSR

	// Continue the flow (runDeprecationFlow) when DOM is ready
	if (document.readyState === 'complete') {
		runDeprecationFlow()
	} else {
		document.addEventListener('readystatechange', function onReadyStateChange() {
			if (document.readyState === 'complete') {
				runDeprecationFlow()
			}
		})
	}
}

function runDeprecationFlow() {
	clearDomFromSSR()
	showDeprecationMessage()
}

function hideBody() {
	const head = document.head || document.getElementsByTagName('head')[0]
	const style = document.createElement('style')

	style.setAttribute('type', 'text/css')
	style.appendChild(document.createTextNode('body { visibility: hidden; }'))

	head.appendChild(style)
}

function clearDomFromSSR() {
	const siteContainer = document.getElementById('SITE_CONTAINER')
	siteContainer && (siteContainer.innerHTML = '')
}

function showDeprecationMessage() {
	const iframe = document.createElement('iframe')
	iframe.setAttribute('src', getIframeUrl())
	iframe.setAttribute('style', 'position: fixed; top: 0; left: 0; width: 100%; height: 100%')
	iframe.onload = function () {
		document.body.style.visibility = 'visible'
	}

	document.body.appendChild(iframe)
}

function getIframeUrl() {
	const userLanguage = window.viewerModel?.language.userLanguage || 'en'
	const supportedLanguages: Record<string, 1> = { pt: 1, fr: 1, es: 1, de: 1, ja: 1, ru: 1, ko: 1 }
	const messageLanguage = supportedLanguages[userLanguage] ? userLanguage : 'en'

	// TODO: Need to make sure these sites are excluded from TB, otherwise we'll have an inception of deprecation iframes
	return `https://${messageLanguage}.wix.com/outdated-browser/internet-explorer?forceBolt&ssrIndicator=false`
}
